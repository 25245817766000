import PagePost from '../models/PagePost';
import PropTypes from 'prop-types';
import React from 'react';
import { error404 as pageMeta } from '../data/page_meta.yml';
import { siteMeta } from '../data/site_meta.yml';

import {
    Hero,
    SEO,
    Waistband
} from '@brandedholdings/react-components';

import PageWrapper from '../components/DefaultPageWrapper';



class ErrorNotFound extends React.Component {
    getChildContext() {
        return { siteMeta };
    }
    
    render() {
        const page = this.props;
        pageMeta.link = page.location.pathname;
        const post = new PagePost(pageMeta, siteMeta);

        return (
            <PageWrapper>
                <SEO post={ post } />


                <Hero
                    heading="Quickly choose from a variety of finance options - customized just for you!"
                    subheading={ null }
                    actionText="See My Options"
                    actionLink="/v2/get-started/"
                    heroModifiers="hero--home"
                    actionModifiers="button--success button--full"
                />
                <div className="hero__accent icon icon--arrow-dotted"></div>


                <div className="layout">
                    <div className="layout-content text-align--center">
                        <h3 className="layout-content__heading">404: Page Not Found</h3>
                        <div className="layout">
                            <div className="layout-content text-align--center box-shadow">
                                <Waistband>
                                    <div className="waistband--content">
                                        <p><strong>We searched</strong> and searched but the page you're looking for just couldn't be found.</p>
                                        <p><a href="/">Visit our homepage</a> to find what you're looking for.</p>
                                    </div>
                                </Waistband>
                            </div>
                        </div>
                    </div>
                </div>




            </PageWrapper>
        );
    }
};

ErrorNotFound.childContextTypes = {
    siteMeta: PropTypes.object
};

export default ErrorNotFound;
